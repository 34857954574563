<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <b-container v-else>
      <h1 class="mr-sm-4 header-tablepage">CREATE RIGHT INFORMATION</h1>
      <div class="mt-3 py-3 pl-3 header-purple d-flex align-items-center">
        <label class="m-0 label-text">Search Customer</label>
      </div>
      <div class="p-3 bg-white">
        <b-row>
          <b-col sm="10"
            ><b-row>
              <b-col sm="4" class="pr-0">
                <label class="mb-2 font-weight-bold">Search Name-Surname</label>
                <b-input-group class="panel-input-serach-extra">
                  <b-form-input
                    id="product-search"
                    class="input-serach-extra"
                    placeholder="Search Name-Surname"
                    @keyup="handleSearch"
                    v-model="filter.name"
                  ></b-form-input>
                  <b-input-group-prepend>
                    <span class="icon-input m-auto pr-3">
                      <font-awesome-icon
                        icon="search"
                        title="View"
                        @click="handleSearch"
                      />
                    </span>
                  </b-input-group-prepend>
                </b-input-group>
              </b-col>
              <b-col sm="4" class="pr-0">
                <label class="mb-2 font-weight-bold"
                  >ค้นหาเบอร์โทรศัพท์ / อีเมล</label
                >
                <b-input-group class="panel-input-serach-extra">
                  <b-form-input
                    id="product-search"
                    class="input-serach-extra"
                    placeholder="ค้นหาเบอร์โทรศัพท์ / อีเมล"
                    v-model="filter.email_tel"
                  ></b-form-input>
                </b-input-group>
              </b-col>
              <b-col sm="4" class="pr-0">
                <div class="font-weight-bold mb-2">วัน/เดือน/ปีเกิด</div>
                <div :class="['input-container']">
                  <datetime
                    :input-style="styleDatetime"
                    v-model="filter.birth_day"
                    placeholder="วว / ดด / ปป"
                    ref="birthday"
                  >
                  </datetime>
                  <div class="icon-primary text-right">
                    <font-awesome-icon
                      v-if="filter.birth_day != ''"
                      class="my-auto text-right pointer mr-2"
                      icon="times-circle"
                      color="#000000"
                      @click="filter.birth_day = ''"
                    />
                    <font-awesome-icon
                      icon="calendar-alt"
                      color="#B41BB4"
                      class="pointer color-primary"
                      @click="$refs.birthday.isOpen = true"
                    />
                  </div>
                </div>
              </b-col> </b-row
          ></b-col>
          <b-col
            sm="2"
            class="pb-1 d-flex justify-content-center align-items-end"
            ><b-button class="btn-action submit-btn" @click="handleSearch">
              ค้นหา
            </b-button></b-col
          >
        </b-row>
        <hr />
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(first_name_th)="data">
                <div class="text-left">
                  <div v-if="data.item.first_name_th">
                    {{ data.item.first_name_th }} {{ data.item.last_name_th }}
                  </div>
                  <div v-else>-</div>
                </div>
              </template>
              <template v-slot:cell(birthday)="data">
                <div v-if="data.item.birthday">
                  {{
                    data.item.birthday == "****"
                      ? data.item.birthday
                      : new Date(data.item.birthday) | moment($formatDate)
                  }}
                </div>
                <div v-else>-</div>
              </template>
              <template v-slot:cell(manage)="data">
                <div>
                  <b-button
                    class="button-select-user-fill ml-2"
                    v-if="checkSelect(data.item.user_guid)"
                    @click="selectCustomer(data.item.user_guid, 1)"
                  >
                    <font-awesome-icon
                      icon="filter"
                      title="filter-btn"
                      class="main-color mr-0 mr-sm-1"
                    />
                    <span class="d-none d-sm-inline">เลือกแล้ว </span>
                  </b-button>
                  <b-button
                    class="button-select-user ml-2"
                    v-if="!checkSelect(data.item.user_guid)"
                    @click="selectCustomer(data.item.user_guid, 0)"
                  >
                    <span class="d-none d-sm-inline">เลือก</span>
                  </b-button>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" class="form-inline justify-content-start">
            <p class="mb-0 p-gray">
              Showing {{ rows > 0 ? showing : 0 }} to
              {{ showingTo > rows ? rows : showingTo }} of {{ rows }} entries
            </p>
          </b-col>
          <b-col cols="12" md="6" class="form-inline justify-content-end">
            <b-pagination
              v-model="filter.page"
              :total-rows="rows"
              :per-page="filter.take"
              first-text="First"
              prev-text="Prev"
              next-text="Next"
              last-text="Last"
              class="m-md-0"
              @change="pagination"
            ></b-pagination>
            <b-form-select
              class="ml-2"
              v-model="filter.take"
              @change="handleChangeTake"
              :options="pageOptions"
            ></b-form-select>
          </b-col>
        </b-row>
        <div class="text-error" v-if="$v.form.user_guid_list.$error">
          Please select user at least 1.
        </div>
      </div>
      <div class="mt-3 py-3 pl-3 header-purple d-flex align-items-center">
        <label class="m-0 label-text">Right Request</label>
      </div>
      <div class="px-2 py-3 bg-white">
        <b-form-checkbox-group
          id="checkbox-group-1"
          class="ml-3 text-black"
          v-model="requestType"
          :options="requestTypeOption"
          @change="selectRequestType"
          stacked
        ></b-form-checkbox-group>
        <div class="text-error pl-3" v-if="$v.form.request_type_id.$error">
          Please select request type at least 1.
        </div>
        <b-row class="pl-3">
          <b-col sm="6">
            <InputTextArea
              textFloat="ระบุเหตุผลเพิ่มเติม"
              type="text"
              v-model="form.note"
              class="mt-2 custom-input"
              placeholder="เหตุผล"
              rows="3"
              name="note"
            />
          </b-col>
        </b-row>
      </div>

      <b-row class="mt-3">
        <b-col sm="6">
          <b-button class="btn-action delete-btn" @click="$router.go(-1)">
            ยกเลิก
          </b-button>
        </b-col>
        <b-col sm="6" class="d-flex justify-content-end">
          <b-button class="btn-action submit-btn" @click="submitForm">
            สร้าง
          </b-button>
        </b-col>
      </b-row>
    </b-container>
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />
  </div>
</template>

<script>
import { required, minValue, minLength } from "vuelidate/lib/validators";
import OtherLoading from "@/components/loading/OtherLoading";
import InputTextArea from "@/components/inputs/InputTextArea";
import ModalAlertError from "@/components/modal/ModalAlertError";
export default {
  name: "CreateAllRightRequest",
  components: {
    OtherLoading,
    InputTextArea,
    ModalAlertError,
  },
  data() {
    return {
      rows: 0,
      showing: 1,
      showingTo: 5,
      filter: {
        name: "",
        email_tel: "",
        birth_day: "",
        take: 5,
        page: 1,
      },
      form: {
        user_guid_list: [],
        request_type_id: 1,
        note: "",
      },
      guidList: [],
      items: [],
      fields: [
        {
          key: "member_id",
          label: "Member ID",
          class: "text-nowrap",
          thStyle: {
            width: "15%",
          },
        },
        {
          key: "first_name_th",
          label: "ชื่อ-นามสกุล",
          class: "text-nowrap",
          thStyle: {
            width: "25%",
          },
        },
        {
          key: "birthday",
          label: "วัน/เดือน/ปีเกิด",
          class: "text-nowrap",
          thStyle: {
            width: "15%",
          },
        },
        {
          key: "telephone",
          label: "เบอร์โทรศัพท์",
          class: "text-nowrap",
          thStyle: {
            width: "20%",
          },
        },
        {
          key: "email",
          label: "อีเมล",
          class: "text-nowrap",
          thStyle: {
            width: "20%",
          },
        },
        {
          key: "manage",
          label: "",
          class: "text-nowrap",
          thStyle: {
            width: "10%",
          },
        },
      ],
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      requestType: [1],
      requestTypeOption: [
        { text: "การเข้าถึงข้อมูล", value: 1 },
        { text: "ขอให้ส่งข้อมูล", value: 2 },
        { text: "ขอให้ถอดถอนข้อมูล", value: 3 },
        { text: "ขอแก้ไขข้อมูล", value: 4 },
        { text: "ระงับ คัดค้านการใช้ข้อมูล", value: 5 },
        { text: "ขอลบข้อมูล", value: 6 },
      ],
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      modalMessage: "",
      isBusy: false,
      isLoading: true,
    };
  },
  validations: {
    form: {
      user_guid_list: { required, minLength: minLength(1) },
      request_type_id: { required, minLength: minLength(1) },
    },
  },
  async created() {
    await this.getCustomerList();
    this.isLoading = false;
  },
  methods: {
    async getCustomerList() {
      this.isBusy = true;
      await this.$store.dispatch("getCustomerCreateRequestList", this.filter);
      let data = this.$store.state.allRightRequest.customerList;
      this.items = data.detail;
      this.rows = data.total_count;
      this.guidList = data.user_guid_list;
      this.isBusy = false;
    },
    handleSearch(e) {
      if (e.type === "keyup") {
        if (e.keyCode === 13) {
          this.filter.page = 1;
          this.showing = 1;
          this.showingTo = this.filter.take;
          this.getCustomerList();
        }
      } else if (e.type === "click") {
        this.filter.page = 1;
        this.showing = 1;
        this.showingTo = this.filter.take;
        this.getCustomerList();
      }
    },
    pagination(value) {
      this.filter.page = value;
      if (value === 1) {
        this.showing = this.filter.page;
        this.showingTo = this.filter.take;
      } else {
        this.showing = value * this.filter.take - this.filter.take;
        this.showingTo = this.showing + this.filter.take;
      }
      this.getCustomerList();
    },
    handleChangeTake(take) {
      this.filter.page = 1;
      this.filter.take = take;
      this.showingTo = this.filter.take;
      this.getCustomerList();
    },
    async submitForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      } else {
        this.isLoading = true;
        await this.$store.dispatch("createRequestInformation", this.form);
        let data =
          this.$store.state.allRightRequest.resCreateRequestInformation;
        if (data.result == 1) {
          this.isLoading = false;
          this.$swal("Create Success!", {
            icon: "success",
          }).then(
            function () {
              window.location.href = `/allrightrequest`;
            }.bind(this)
          );
        } else {
          this.isLoading = false;
          this.$refs.modalAlertError.show();
          this.modalMessage = data.message;
        }
      }
    },
    checkSelect(user_guid) {
      let index = this.form.user_guid_list.findIndex(
        (guid) => guid == user_guid
      );
      return index >= 0 ? true : false;
    },
    selectCustomer(guid, selected) {
      if (selected == 0) {
        this.form.user_guid_list.push(guid);
      } else {
        let index = this.form.user_guid_list.findIndex((list) => list == guid);
        this.form.user_guid_list.splice(index, 1);
      }
    },
    selectRequestType(value) {
      if (value[value.length - 1]) {
        this.requestType = [value[value.length - 1]];
        this.form.request_type_id = value[value.length - 1];
      } else {
        this.requestType = [];
        this.form.request_type_id = null;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .input-custom {
  label {
    color: #3c4b64;
    font-size: 14px;
  }
}
::v-deep .vdatetime-popup {
  z-index: 9999;
}
.ft-16 {
  font-size: 16px;
  color: var(--font-main-color);
}

.button-select-user {
  width: 100px !important;
  height: 38px;
  color: var(--primary-color);
  background-color: #fff;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
}
.button-select-user-fill {
  width: 10 0px !important;
  height: 38px;
  color: var(--primary-color);
  background-color: #e9d2f4;
  border: 1px solid #e9d2f4;
  border-radius: 4px;
}
</style>
